import React, { useMemo, useRef } from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import Container from "../../microcomponents/container/container"
import HeadSubheadHorizontal from "../../microcomponents/headsubheadhorizontal/headsubheadhorizontal"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import Slider from "react-slick"
import Image from "../../components/image-component/image-new"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import { detecttestsslider } from "../../utilities/detecttestsslider"
export default function ScraperV2() {
  let sliderRef = useRef(null)
  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      autoplay: false,
      arrows: false,
      slidesToShow: 3,
      centerMode: true,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 2600,
          settings: {
            centerPadding: "0px",
          },
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 1,
            centerPadding: "520px",
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 1,
            centerPadding: "270px",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            centerPadding: "150px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            centerPadding: "128px",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            centerPadding: "60px",
          },
        },
        // {
        //   breakpoint: 991,
        //   settings: {
        //     centerMode: true,
        //     centerPadding: "60px",
        //     slidesToShow: 3,
        //   },
        // },
        {
          breakpoint: 767,
          settings: {
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    }
  }, [])

  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="blueeclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name:
              "How <span class='hidedesktop hidetablet hideipad hidemobile'>it Works</span>",
            url: "/how-it-works/",
          },
          {
            name: "Prediction <span class='hidemobile'>Engine</span>",
            url: "/prediction-engine/",
          },
          { name: "Scraper", url: null },
        ]}
        subHeading={null}
        heading="Capture the <br class='hideipad'/> wisdom from<br class='hidedesktop hidetablet hidemobile'/> all<br class='hideipad hidemobile'/> <span>online A/B tests.</span>"
        description="<p>The scraper identifies all A/B tests <br class='hidedesktop hidetablet hideipad'/> running on<br class='hideipad hidemobile'/> 78,000+ sites and <br class='hidedesktop hidetablet hidemobile'/> tracks <br class='hidedesktop hidetablet hideipad'/> the outcomes. So<br class='hideipad hidemobile'/> you run only the <br class='hidedesktop hidetablet hideipad'/> most proven winning <br class='hidedesktop hidetablet hidemobile'/> tests.</p>"
        tags={["Detect Tests", "Identify Winners", "Repository"]}
        heroImage="interior_hero_2.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
      />
      <ClientLogos version="v2" />
      <div className="gray-bg pd-112" id="detecttests">
        <Container>
          <HeadSubheadHorizontal
            className="vertical-360"
            subHeading="Detect Tests"
            btnComponent={
              <div className="hidemobile">
                <Button
                  version={`v2`}
                  icon={<ArrowIcon />}
                  type={`cta`}
                  href={`/get-demo/`}
                >
                  Get a Demo
                </Button>
              </div>
            }
            description={`<p>The scraper tool identifies test running on the 78,000 sites that actively A/B test. It visits these sites from multiple IPs to see if different page versions are displayed. It then captures the control and variant(s).</p>`}
          >
            Detect tests across 78,000 sites.
          </HeadSubheadHorizontal>
          <div
            className="hidedesktop hidetablet hideipad"
            style={{
              marginTop: "40px",
              width: "fit-content",
            }}
          >
            <Button
              version={`v2`}
              icon={<ArrowIcon />}
              type={`cta`}
              href={`/get-demo/`}
            >
              Get a Demo
            </Button>
          </div>
        </Container>
        <div className="full-vw-section-wrapper image-continous-slider">
          <Slider
            ref={slider => {
              sliderRef = slider
            }}
            className="multiple-tile-image-slider plusminusslides"
            {...settings}
          >
            {detecttestsslider?.map((slide, index) => (
              <div
                className="image-tile-full"
                key={index}
                onClick={e => {
                  sliderRef.slickGoTo(
                    Number(
                      e.currentTarget
                        .closest(".slick-slide")
                        .getAttribute("data-index")
                    )
                  )
                }}
              >
                <Image
                  sourceFolder={"spiralyze2024website"}
                  lazyLoad={"true"}
                  fallBackImage={`comparison_slider_13.webp`}
                  imgName={slide.image}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <LeftRightImageText
        mainHeading="Detecting winners. 8,000+ <br class='hidedesktop hidetablet hidemobile'/> new tests every month."
        subHeading="Identify Winners"
        sectionDescription="<p>Tests are monitored to see whether the control or <br class='hidedesktop hidetablet hidemobile'/> variation is retained  <br class='hidedesktop hidetablet hideipad'/> after the test ends — and was thus <br class='hidedesktop hidetablet'/> the winner. </p><p>Over 8,000 new test results every  <br class='hidedesktop hidetablet hideipad'/> month, so you know <br class='hidedesktop hidetablet hidemobile'/> exactly what  <br class='hidedesktop hidetablet hideipad'/> types of tests are winning for  <br class='hidedesktop hidetablet hideipad'/> companies like <br class='hidedesktop hidetablet hidemobile'/> yours.  </p>"
        innerClasses="reverse pd-112-top"
        id="identifywinners"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`frame_1171275348.webp`}
              imgName={[
                {
                  imageName: "frame_1171275348.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "frame_1171275356.webp",
                  minBreakpoint: "0px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Biggest testing repository.<br class='hidedesktop hidetablet hidemobile'/> <span> 206,000 tests & growing.</span>"
        // subHeading="Identify Winners"
        sectionDescription="<p>Our A/B test repository contains 50X <br class='hidedesktop hidetablet hideipad'/> more testing data than any <br class='hidedesktop hidetablet hidemobile'/> other <br class='hidedesktop hidetablet hideipad'/> source. This lets you predict the tests <br class='hidedesktop hidetablet hideipad'/> most likely to win for <br class='hidedesktop hidetablet hidemobile'/> your industry, customer profile, and page type.</p>"
        innerClasses="pd-112 dark-theme"
        wrapperClasses="blue-bg"
        id="repository"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`frame_1171275346.webp`}
              imgName={`frame_1171275346.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>Number of A/B tests each year.</p>
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2 lite" />
      <ClientResults version="v2" background="white" />
    </div>
  )
}
