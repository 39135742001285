import * as React from "react"
import SeoNoIndex from "../components/seo-no-index-ablyft"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import ScraperV2 from "../componentsv2/scraperv2/scraperv2"

const Scraper = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <SeoNoIndex title="Scraper" index={false} follow={false} />
    <ScraperV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default Scraper
