export const detecttestsslider = [
  {
    title: "Monday",
    image: "comparison_slider_13.webp",
  },
  {
    title: "Smart Sheet",
    image: "comparison_slider_16.webp",
  },
  {
    title: "Zoominfo",
    image: "comparison_slider_15.webp",
  },
  {
    title: "Confluent",
    image: "comparison_slider_14.webp",
  },
]
